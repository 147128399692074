'use client';
import ErrorMessageClient from '~/components/customComponents/error-message/ErrorMessageClient';
import Flex from '~/components/customComponents/flex/Flex';

const NotFound = () => {
  return (
    <Flex className="w-full h-screen">
      <ErrorMessageClient titleKey="global.error.title" showRefreshBtn />
    </Flex>
  );
};

export default NotFound;
